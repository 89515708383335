import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';

import {
  FETCH_OWN_PROFILE_REQUEST,
} from '@constants/iObserverActionTypes';

import {
  getProfileSuccess,
} from '@actions/iObserverActions/ProfileActions';

import { rest } from '@api/iObserverApi';

function* fetchOwnProfile() {
  try {
    const fetchedProfile = yield call(rest.getProfileInfo);
    if (fetchedProfile) {
      yield put(getProfileSuccess({ ...fetchedProfile }));
    }
  } catch (error) {
    console.error('Fetch profile error: ', error);
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_OWN_PROFILE_REQUEST, fetchOwnProfile);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}

import {
  TRANSFER_SESSION_REQUEST,
  TRANSFER_SESSION_SUCCESS,
  CANCEL_TRANSFER_SESSION_REQUEST,
  CANCEL_TRANSFER_SESSION_SUCCESS,
  CLEAN_UP_SESSION_AFTER_TRANSFER,
  LOAD_IMPORTED_ENDPOINTS,
  INITIALIZE_TRANSFER_SESSION_WS_CHANNEL,
  TRANSFER_SESSION_WS_MESSAGE_RECEIVED,
  SEND_COMMAND_BY_USER,
  GET_TRANSFERS_REQUEST,
  GET_TRANSFERS_SUCCESS,
  START_IMPORT_SESSION,
  SET_SESSION_POPUP_MESSAGE,
} from '@constants/iObserverActionTypes';

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const transferSessionRequest = (payload) => ({
  type: TRANSFER_SESSION_REQUEST,
  payload,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const transferSessionSuccess = (transfer_id) => ({
  type: TRANSFER_SESSION_SUCCESS,
  payload: {
    transfer_id,
  },
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const cancelTransferSessionRequest = (payload) => ({
  type: CANCEL_TRANSFER_SESSION_REQUEST,
  payload,
});

/**
 * @return {Object}
 */
export const cancelTransferSessionSuccess = () => ({
  type: CANCEL_TRANSFER_SESSION_SUCCESS,
});

/**
 * @return {Object}
 */
export const cleanUpSessionAfterTransfer = () => ({
  type: CLEAN_UP_SESSION_AFTER_TRANSFER,
});

/**
 * @return {Object}
 */
export const loadImportedSession = (payload) => ({
  type: LOAD_IMPORTED_ENDPOINTS,
  payload,
});

/**
 * @return {Object}
 */
export const initTransferSessionWs = () => ({
  type: INITIALIZE_TRANSFER_SESSION_WS_CHANNEL,
});

/**
 * @return {Object}
 */
export const onTransferSessionWsMessageReceived = (message) => ({
  type: TRANSFER_SESSION_WS_MESSAGE_RECEIVED,
  payload: {
    message,
  },
});

/**
 * @return {Object}
 */
export const sendCommandByUser = (params) => ({
  type: SEND_COMMAND_BY_USER,
  payload: {
    params,
  },
});

/**
 * @return {Object}
 */
export const getTransfersRequest = () => ({
  type: GET_TRANSFERS_REQUEST,
});

/**
 * @return {Object}
 * @param payload
 */
export const getTransfersSuccess = (payload) => ({
  type: GET_TRANSFERS_SUCCESS,
  payload,
});

/**
 * @param  {Object} transfer_id
 * @return {Object}
 */
export const startImportSession = (transfer_id) => ({
  type: START_IMPORT_SESSION,
  payload: {
    transfer_id,
  },
});
/**
 * @return {Object}
 */
export const transferComplete = (email) => ({
  type: SEND_COMMAND_BY_USER,
  payload: {
    params: {
      email,
      command: 'transfer_complete',
      params_p: null,
    },
  },
});

/**
 * @return {Object}
 * @param message
 */
export const setPopUpMessage = (message) => ({
  type: SET_SESSION_POPUP_MESSAGE,
  payload: {
    popUpMessage: message,
  },
});

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import {
  Route, useLocation, Switch,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import { setInitUrl } from 'appRedux/actions/Auth';
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from 'appRedux/actions/Setting';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '@constants/ThemeSetting';

import AutoMonitorRoom from '../../routes/AutoMonitorRoom';

const App = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings,
  );
  const { initURL } = useSelector(({ auth }) => auth);

  // useEffect(() => {
  //   let link = document.createElement('link');
  //   link.type = 'text/css';
  //   link.rel = 'stylesheet';
  //   link.href = '/css/style.css';
  //   link.className = 'gx-style';
  //   document.body.appendChild(link);
  // });

  const setLayoutType = (layoutTyp) => {
    if (layoutTyp === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutTyp === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutTyp === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setJACss = () => {
    // adding / removing css class fixes for Arabic and Japanese language layout
    switch (locale.locale) {
      case 'ja': { // japanese
        // removing css class fixes for arabic
        document.body.classList.remove('ar-full-layout');

        document.body.classList.add('ja-full-layout');
        break;
      }
      case 'ar': { // arabic
        // removing css class fixes for japanese
        document.body.classList.remove('ja-full-layout');

        document.body.classList.add('ar-full-layout');
        break;
      }
      default: {
        document.body.classList.remove('ja-full-layout', 'ar-full-layout');
        break;
      }
    }
  };

  const setNavStyle = (naviStyle) => {
    if (
      naviStyle === NAV_STYLE_DEFAULT_HORIZONTAL
      || naviStyle === NAV_STYLE_DARK_HORIZONTAL
      || naviStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL
      || naviStyle === NAV_STYLE_ABOVE_HEADER
      || naviStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
    setJACss();
  });

  const currentAppLocale = AppLocale[locale.locale];

  const flattenMessages = (nestedMessages, prefix = '') => Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={flattenMessages(currentAppLocale.messages)}
      >
        <Switch>
          <Route exact path="/automonitor/:apiUsername/:encryptedData" component={AutoMonitorRoom} />
          {/* <Route path={`${match.url}`} location={location} component={MainApp} /> */}
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

function NoMatch() {
  return (
    <div className="gx-page-error-container">
      <div className="gx-error-code gx-mb-4">
        404
      </div>
    </div>
  );
}

export default memo(App);

import {
  LISTINGS_FILTER_FETCH_SUCCESS,
  RESET_LISTING_FILTERS,
} from '@constants/iObserverActionTypes';

const INIT_STATE = {
  organization: [],
  customer: [],
  facility: [],
  unit: [],
  endpoint: [],
  lastSelection: {
    customer: '',
    facility: '',
    unit: '',
    endpoint: '',
  },
};

function getUpdatedState(currentState, key, newData, keep, filter) {
  let newState = {
    ...INIT_STATE,
  };
  if (keep) {
    newState.customer = [...currentState.customer];
    newState.facility = [...currentState.facility];
    newState.unit = [...currentState.unit];
    newState.endpoint = [...currentState.endpoint];
  }
  switch (key) {
    case 'customer':
      newState.customer = [...newData];
      newState.lastSelection = { ...INIT_STATE.lastSelection };
      break;
    case 'facility':
      newState.customer = [...currentState.customer];
      newState.facility = [...newData];
      newState.lastSelection.customer = filter;
      newState.lastSelection.facility = '';
      newState.lastSelection.unit = '';
      newState.lastSelection.endpoint = '';
      break;
    case 'unit':
      newState.customer = [...currentState.customer];
      newState.facility = [...currentState.facility];
      newState.unit = [...newData];
      newState.lastSelection.facility = filter;
      newState.lastSelection.unit = '';
      newState.lastSelection.endpoint = '';
      break;
    case 'endpoint':
      newState.customer = [...currentState.customer];
      newState.facility = [...currentState.facility];
      newState.unit = [...currentState.unit];
      newState.endpoint = [...newData];
      newState.lastSelection.unit = filter;
      newState.lastSelection.endpoint = '';
      break;
    default:
      newState = { ...currentState };
  }
  return newState;
}
/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTINGS_FILTER_FETCH_SUCCESS: {
      const newState =
        getUpdatedState(state, action.key, action.payload.list, action.keep, action.filter);
      return { ...newState };
    }
    case RESET_LISTING_FILTERS:
      // return {...INIT_STATE};
      return {};
    default:
      return state;
  }
};

import {
  ENDPOINT_STATUSES,
} from '@constants/Settings';

export const getSelectedEndpoints = (state) => {
  const selectedEndpoints = {};
  Object.keys(state.endpoints.selectedEndpoints).forEach((e) => {
    const endpoint = state.endpoints.selectedEndpoints[e];
    if (endpoint.status === ENDPOINT_STATUSES.ADMITTED
      || endpoint.status === ENDPOINT_STATUSES.APPLIED) {
      selectedEndpoints[e] = endpoint;
    }
  });
  return selectedEndpoints;
};

export const getEndpointByMachineName = (state, machineName) => {
  const endpointToReturn = Object.values(state.endpoints.selectedEndpoints).find(
    (endpoint) => endpoint && endpoint.machine_name === machineName,
  );
  return { ...endpointToReturn };
};

export const getAdmittedEndpoints = (state) => {
  const admittedEndpoints = {};
  Object.keys(state.endpoints.selectedEndpoints).forEach((endpointName) => {
    const endpoint = state.endpoints.selectedEndpoints[endpointName];
    if (endpoint.status === ENDPOINT_STATUSES.ADMITTED) {
      admittedEndpoints[endpointName] = endpoint;
    }
  });

  return admittedEndpoints;
};

export const getAppliedEndpoints = (state) => {
  const appliedEndpoints = {};
  Object.keys(state.endpoints.selectedEndpoints).forEach((e) => {
    const endpoint = state.endpoints.selectedEndpoints[e];
    if (endpoint.status === ENDPOINT_STATUSES.APPLIED) {
      appliedEndpoints[e] = endpoint;
    }
  });

  return appliedEndpoints;
};
export const getRemovedEndpoints = (state) => {
  const removedEndpoints = {};
  if (Object.keys(state.endpoints.selectedEndpoints).length) {
    for (const e in state.endpoints.selectedEndpoints) {
      const endpoint = state.endpoints.selectedEndpoints[e];
      if (endpoint.status === ENDPOINT_STATUSES.REMOVED) {
        removedEndpoints[e] = endpoint;
      }
    }
  }

  return removedEndpoints;
};

export const selectEndpointById = (state, id) => {
  let endpoint = null;
  if (state.endpoints.selectedEndpoints[id]) {
    endpoint = { ...state.endpoints.selectedEndpoints[id] };
  }

  return endpoint;
};

export const getPatientRisksList = (state) => state.endpoints.endpointRisksList;

export const getObservationRisksList = (state) => state.endpoints.endpointObservationRisksList;

export const getAdminId = (state) => state.profile.adminId;
export const getObservationSession = (state) => state.observation.session;
export const getObservationSessionId = (state) => state.observation.session.observation_session_id;

export const getProfile = (state) => state.profile;

export const getSessionPopUpMessage = (state) => state.observation.popUpMessage;

export const getChangedEndpoints = (state) => {
  const changedEndpoints = [];

  if (Object.keys(state.endpoints.selectedEndpoints).length) {
    for (const e in state.endpoints.selectedEndpoints) {
      const endpoint = state.endpoints.selectedEndpoints[e];
      if (endpoint.status === ENDPOINT_STATUSES.APPLIED && endpoint.patientInfo.dirty) {
        changedEndpoints.push(endpoint);
      }
    }
  }
  return changedEndpoints;
};

export const getSelectedEndpointObservation = (state) => {
  if (Object.keys(state.endpoints.selectedEndpoints).length) {
    for (const e in state.endpoints.selectedEndpoints) {
      const endpoint = state.endpoints.selectedEndpoints[e];
      if (endpoint.status === ENDPOINT_STATUSES.APPLIED && endpoint.selected === true) {
        return endpoint;
      }
    }
  }
  return null;
};

export const getSelectedEndpointId = (state) => state.endpoints.selectedEndpoint;

export const getSelectedEndpoint = (state) => selectEndpointById(
  state, state.endpoints.selectedEndpoint,
);

export const getListenReasons = (state) => state.observation.listenReasons;

export const getTalkReasons = (state) => state.observation.talkReasons;

export const getNotifyReasons = (state) => state.observation.notifyReasons;
export const getTransferId = (state) => state.observation.transfer_id;

export const getTransfers = (state) => state.observation.transfers;

export const getSelectedEndpointMachineNames = (state) => {
  let arr = [];
  const selEndpointKeys = Object.keys(state.endpoints.selectedEndpoints);
  if (selEndpointKeys) {
    arr = selEndpointKeys.map((key) => state.endpoints.selectedEndpoints[key].machine_name);
  }
  return arr;
};

export const getObservationLoading = (state) => state.observation.loading;

export const getAudioVideo = (state) => state.observation.audioVideo;

export const getAudioVideoLoading = (state) => state.observation.loadingAudioVideo;

export const getLastDropdownSelection = (state) => state.listingsFilter.lastSelection;

export const getObservationInitResult = (state) => state.profile.initResult;

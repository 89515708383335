import {
  INIT_URL,
  SIGNOUT_USER,
} from "constants/ActionTypes";

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

import { BASE_URL } from '@constants/Endpoints';
import {
  ACCESS_TOKEN_KEY,
  HTTP_ERROR_MESSAGES,
  IMPERSONATE_TOKEN,
} from '@constants/Settings';
import {
  HTTPResponseError,
  HTTPResponseWarning,
  ServerError,
} from '@constants/ServerExceptions';

const defaultPostHeaders = [
  {
    name: 'Content-Type',
    value: 'application/json',
  },
];

const RestManager = {
  formDataRequest: async (url, data, headers = [], isEssential = true) => {
    const options = {
      method: 'POST',
      headers: RestManager.getHeaders(headers),
      body: data,
    };
    const res = await fetch(BASE_URL + url, options).then((response) => {
      RestManager.checkResponseStatus(response, isEssential);
      return RestManager.tryResponseJSON(response);
    });

    return res;
  },

  request: async (url, method = 'GET', headers = [], isEssential = true) => {
    const options = {
      method,
      headers: RestManager.getHeaders(headers),
      mode: 'cors',
      cache: 'default',
    };
    const json = await fetch(BASE_URL + url, options).then((response) => {
      RestManager.checkResponseStatus(response, isEssential);
      return RestManager.tryResponseJSON(response);
    });

    return json;
  },

  tryResponseJSON: async (response) => {
    try {
      return await response.json();
    } catch (e) {
      console.log('tryResponseJSON: ', e);
      return {};
    }
  },

  formDataReq: async (
    url,
    method,
    bodyData = {},
    headers = [],
    isEssential = true,
    isAuthBearer = true,
  ) => {
    const options = {
      method,
      headers: RestManager.getHeaders(
        [
          {
            name: 'Content-Type',
            value: 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          ...headers,
        ],
        isAuthBearer,
      ),
      mode: 'cors',
      cache: 'default',
      body: bodyData,
    };
    const finalUrl = BASE_URL + url;
    const json = await fetch(finalUrl, options).then(async (response) => {
      if (method === 'DELETE') {
        return { status: response.status };
      }
      // const res = await response.json();
      RestManager.checkResponseStatus(response, isEssential);
      return RestManager.tryResponseJSON(response);
    });

    return json;
  },
  requestWithoutQueryParams: async (
    url,
    method,
    bodyData = {},
    headers = [],
    isEssential = true,
    isAuthBearer = true,
  ) => {
    const options = {
      method,
      headers: RestManager.getHeaders(
        [...defaultPostHeaders, ...headers],
        isAuthBearer,
      ),
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(bodyData),
    };
    const finalUrl = BASE_URL + url;
    const json = await fetch(finalUrl, options).then(async (response) => {
      if (method === 'DELETE') {
        return { status: response.status };
      }
      // const res = await response.json();
      RestManager.checkResponseStatus(response, isEssential);
      return RestManager.tryResponseJSON(response);
    });

    return json;
  },

  getAuthToken() {
    if (sessionStorage?.getItem(ACCESS_TOKEN_KEY)) {
      return sessionStorage.getItem(ACCESS_TOKEN_KEY);
    }

    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },

  getHeaders(additionalHeaders, isAuthBearer = true) {
    const headers = new Headers();

    if (isAuthBearer) {
      const accessToken = RestManager.getAuthToken();
      const impersonateToken = localStorage.getItem(IMPERSONATE_TOKEN);
      headers.append('Authorization', impersonateToken !== null ? `Bearer ${impersonateToken}` : `Bearer ${accessToken}`);
    }

    additionalHeaders.forEach((item) => {
      headers.append(item.name, item.value);
    });

    return headers;
  },

  checkResponseStatus(response, isEssential = true) {
    if (isEssential && response.status === 401) {
      window.location.href = `${process.env.REACT_APP_SSO_REDIRECT_URL}${window.location.pathname.includes('automonitor') ? window.location.pathname : ''}`;
    } else if (response.status < 200 || response.status > 204) {
      RestManager.handleErrorResponse(response, isEssential);
    }
  },

  handleErrorResponse(response, isEssential) {
    let error;

    switch (response.status) {
      case 400:
        if (isEssential && response.message === 'Missing token') {
          error = new HTTPResponseError('missing_token', response.status);
        } else if (isEssential && response.message === 'Invalid token') {
          error = new HTTPResponseError('invalid_token', response.status);
        } else if (isEssential && response.message === 'Password too short') {
          error = new HTTPResponseError('short_password', response.status);
        } else if (isEssential && response.message === 'Password too simple') {
          error = new HTTPResponseError('simple_password', response.status);
        } else if (isEssential) {
          error = new HTTPResponseError(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        } else {
          error = new HTTPResponseWarning(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        }
        break;
      case 403:
      case 404:
      case 409:
        if (isEssential) {
          error = new HTTPResponseError(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        } else {
          error = new HTTPResponseWarning(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        }
        break;
      case 500:
      case 502:
      case 503:
      case 504:
        error = new ServerError(HTTP_ERROR_MESSAGES[response.status]);
        break;
      default:
        error = new HTTPResponseError(response.message, response.status);
    }

    throw error;
  },
};

export default RestManager;

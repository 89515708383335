import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import EpicMonitorHandler from '@util/EpicMonitorHandler';
import { v4 as uuidv4 } from 'uuid';
import EndpointVideoElement from 'src/components/EndpointVideo/EndpointVideoElement/EndpointVideoElement';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loaderSvg from '@assets/images/loader.svg';

import { getObservationInitResult } from '@selectors/iObserverSelectors';
import { getIObserverControllerWsAddresses } from '@selectors/commonSelectors';
import WebRTCManager from '../util/WebRTC/WebRTCManager';
import {
  goToPoint, initObservation,
  zoomIn,
  zoomOut,
  zoomStop,
} from '../appRedux/actions';

const AutoMonitorRoom = (props) => {
  const [streamVideo, setStreamVideo] = useState();
  const videoEl = useRef(null);
  const [nodeId, setNodeId] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { encryptedData } = useParams();
  const { apiUsername } = useParams();
  const username = `${apiUsername?.toLowerCase()}-${uuidv4()}`;
  let epicMonitorHandler;
  let loginTimeout;
  const { obsInitStatus, iObserverControllerWsAddresses } = props;
  const {
    success, errorCode, loading, epic_client_id, endpoint,
  } = obsInitStatus;

  useEffect(() => {
    const { initObservationProp } = props;
    initObservationProp(apiUsername, encryptedData);

    return () => {
      // Clean up
      clearTimeout(loginTimeout);
      if (nodeId) {
        WebRTCManager.directUnlink(`camera_${nodeId}`, apiUsername);
        WebRTCManager.closeUserSession();
      }

      if (epicMonitorHandler) {
        epicMonitorHandler.cleanup();
        epicMonitorHandler = null;
      }
    };
  }, []);

  const updateErrorMessage = () => {
    let message = null;
    if (errorCode) {
      const { intl } = props;
      message = intl.formatMessage({ id: 'auto_monitor.error_auth' });
    }
    setErrorMessage(message);
  };

  useEffect(() => {
    updateErrorMessage();
    if (!success) {
      return;
    }
    const { machine_name } = endpoint;
    setNodeId(machine_name);
    WebRTCManager.connect(iObserverControllerWsAddresses);
    WebRTCManager.onConnect = () => {
      WebRTCManager.directLink(`camera_${machine_name}`, username, { calibrateMotion: false });

      loginTimeout = setTimeout(() => {
        WebRTCManager.login(username);
      }, 300);
    };

    WebRTCManager.onRemoteStreamStart = (mediaId, stream) => {
      setStreamVideo(stream);
      if (videoEl && videoEl.current && mediaId === `camera_${machine_name}`) {
        videoEl.current.srcObject = stream;

        if (epicMonitorHandler) {
          epicMonitorHandler.reportCurrentStateUpdate();
        }
      } else if (mediaId !== `camera_${machine_name}`) {
        console.log('[auto-monitor onRemoteStreamStart: unlink', mediaId, `camera_${machine_name}`);
        WebRTCManager.directUnlink(mediaId, username);
      }
    };
    WebRTCManager.onRemoteStreamStop = (mediaId) => {
      if (videoEl && videoEl.current && mediaId === `camera_${machine_name}`) {
        videoEl.current.srcObject = null;

        if (epicMonitorHandler) {
          epicMonitorHandler.reportCurrentStateUpdate(true);
        }
      }
    };

    if (!epicMonitorHandler) {
      epicMonitorHandler = new EpicMonitorHandler();
      epicMonitorHandler.initHandler(videoEl, `camera_${machine_name}`, epic_client_id);
    }
  }, [success]);

  // const startVideo = () => {
  //   if (videoEl && videoEl.current) {
  //     videoEl.current.play();
  //   }
  // };

  const handleZoom = (stop, toZoomIn) => {
    if (stop) {
      const { zoomStopAction } = props;
      zoomStopAction(nodeId);
    } else if (toZoomIn) {
      const { zoomInAction } = props;
      zoomInAction(nodeId);
    } else {
      const { zoomOutAction } = props;
      zoomOutAction(nodeId);
    }
  };

  const goTo = (x, y, zoom) => {
    props.goToPointAction(nodeId, x, y, zoom);
  };

  const handleVideoRefCallback = (videoRef) => {
    videoEl.current = videoRef;
  };

  return (
    <div className="endpoint-video" style={{ cursor: 'pointer' }}>
      {((success === null && loading) || (success && !streamVideo)) && !errorMessage && (
        <div className="loader loading">
          <img src={loaderSvg} alt="loader" />
        </div>
      )}
      {success === false && !!errorMessage && (
        <div className="error-container">
          <h2>{ errorMessage }</h2>
        </div>
      )}
      {streamVideo && (
        <EndpointVideoElement
          stream={streamVideo}
          goToPoint={goTo}
          zoom={handleZoom}
          muted
          setVideoRefCallback={handleVideoRefCallback}
        />
      )}
    </div>
  );
};

AutoMonitorRoom.propTypes = {
  intl: PropTypes.shape().isRequired,
  goToPointAction: PropTypes.func.isRequired,
  zoomInAction: PropTypes.func.isRequired,
  zoomStopAction: PropTypes.func.isRequired,
  zoomOutAction: PropTypes.func.isRequired,
  initObservationProp: PropTypes.func.isRequired,
  obsInitStatus: PropTypes.shape(
    {
      success: PropTypes.bool,
      errorCode: PropTypes.number,
      data: PropTypes.shape(),
      loading: PropTypes.bool,
      epic_client_id: PropTypes.string,
      endpoint: PropTypes.shape(
        { machine_name: PropTypes.string },
      ),
    },
  ).isRequired,
  iObserverControllerWsAddresses: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  obsInitStatus: getObservationInitResult(store),
  iObserverControllerWsAddresses: getIObserverControllerWsAddresses(store),
});

const mapDispatchToProps = (dispatch) => ({
  goToPointAction: (machineName, x, y, zoom) => dispatch(goToPoint(machineName, x, y, zoom)),
  zoomInAction: (machineName) => dispatch(zoomIn(machineName)),
  zoomOutAction: (machineName) => dispatch(zoomOut(machineName)),
  zoomStopAction: (machineName) => dispatch(zoomStop(machineName)),
  initObservationProp: (api_username, data) => dispatch(initObservation({ api_username, data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AutoMonitorRoom));

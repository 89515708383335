export const BASE_URL = '/api';
export const ENDPOINTS = {
  AUTH: {
    REFRESH_TOKEN: '',
    LOGOUT: '',
  },
  API: {
    FETCH_PROFILE: '/authentication/principal',
    FILTER: {
      organization: '/observation/organizations?projection=base',
      customer: '/observation/customers?projection=base',
      facility: '/observation/facilities?projection=base',
      unit: '/observation/units?projection=base',
      endpoint: '/observation/endpoints?projection=base',
    },
    CUSTOMER: {
      fetchById: '/observation/customers',
      fetchCustomers: '/observation/customers',
    },
    FACILITY: {

    },
    UNIT: {

    },
    ENDPOINT: {
      fetchById: '/observation/endpoints',
      fetchEndpoints: '/observation/endpoints',
      fetchEndpointRisks: '/observation/patient_profile/risk',
      fetchObservationRisks: '/observation/patient_profile/type',
      fetchEndpointDetails: '/observation/get_endpoint_details',
      getStatus: '/observation/endpoints',
      esitterToggle: '/observation/endpoint/adjust_esitter',
    },
    OBSERVATION: {
      startSession: '/observation/start_observation_session',
      transferSession: '/observation/transfer_session',
      cancelTransferSession: '/observation/cancel_transfer_session',
      startRoomObservation: '/observation/start_room_observation',
      updateRoomObservation: '/observation/update_room_observation',
      endRoomObservation: '/observation/end_room_observation',
      sendCommandByMachineName: '/observation/command/send_command_by_machine_name',
      refreshCameraObservation: '/observation/refresh_camera_observation_activity',
      startObservationActivity: '/observation/start_observation_activity',
      endObservationActivity: '/observation/end_observation_activity',

      fetchListenReasons: '/observation/intervention_reason/listen',
      fetchTalkReasons: '/observation/intervention_reason/talk',
      fetchAlertReasons: '/observation/intervention_reason/notify',
      // sendCommandByUser: '/observation/command/send_command_by_user',
      sendCommandByUser: '/observation/command/send_command_to_user',
      getTransfers: '/observation/get_transfers',
      takeOverSession: '/observation/take_over_session',
      audioVideo: '/observation/audio_video',
      textMessage: '/observation/text_message',
    },
    SETTINGS: {
      iObserverControllerSettings: '/configurables/esitter_controller_default',
    },
    MONITOR: {
      init: '/init',
      sendCommandByMachineName: '/command/send_command_by_machine_name',
      refresh: '/refresh',
    },
  },

};

import jwt_decode from 'jwt-decode';
import { cloneDeep } from 'lodash';
import {
  FETCH_OWN_PROFILE_SUCCESS,
  CLEAN_UP_DATA_ON_LOGOUT, INIT_OBSERVATION_RESULT, INIT_OBSERVATION,
} from '@constants/iObserverActionTypes';
import {
  ACCESS_TOKEN_KEY,
} from '@constants/Settings';

const initialSettings = {
  adminId: -1,
  profile: {},
  initResult: {
    loading: false,
    success: null,
    epic_client_id: null,
    errorCode: null,
    data: null,
  },
};

const profile = (state = cloneDeep(initialSettings), action) => {
  let decodedToken;
  switch (action.type) {
    case FETCH_OWN_PROFILE_SUCCESS:
      decodedToken = jwt_decode(localStorage.getItem(ACCESS_TOKEN_KEY));
      return {
        ...state,
        profile: { ...action.payload },
        adminId: decodedToken.admin_id || '',
      };
    case CLEAN_UP_DATA_ON_LOGOUT:
      return {
        ...cloneDeep(initialSettings),
      };
    case INIT_OBSERVATION:
      return {
        ...state,
        initResult: { ...state.initResult, loading: true },
      };
    case INIT_OBSERVATION_RESULT:
      return {
        ...state,
        initResult: { ...action.payload, loading: false },
      };
    default:
      return state;
  }
};

export default profile;

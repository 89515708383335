import {
  all, call, fork, put, takeEvery, select, delay,
} from 'redux-saga/effects';
import {
  applyChangesSuccess,
  cleanUpDataOnLogout, initObservationResult,
} from '@actions/iObserverActions/GlobalActions';

import {
  getAdminId,
  getObservationSessionId,
  getAdmittedEndpoints,
  getRemovedEndpoints,
  getChangedEndpoints,
  getAppliedEndpoints,
} from '@selectors/iObserverSelectors';

import {
  APPLY_CHANGES,
  END_OBSERVATION_SESSION_REQUEST,
  LOGOUT,
  CLEAN_UP_SESSION_AFTER_TRANSFER,
  START_OBSERVATIONS,
  FETCH_IOBSERVER_CONTROLER_SETTINGS_REQUEST, INIT_OBSERVATION,
} from '@constants/iObserverActionTypes';

import {
  startRoomObservation,
  // endRoomObservation,
  updateRoomObservation,
  startObservationSessionSuccess,
  cleanUpSession,
  endRoomObservationSuccess,
} from '@actions/iObserverActions/ObservationActions';

import {
  ACCESS_TOKEN_KEY,
  CONTROL_COMMANDS,
  PARAMS_PER_COMMAND, REFRESH_TOKEN_KEY,
} from '@constants/Settings';

import { rest } from '@api/iObserverApi';

import { fetchError, fetchIObserverControllerSettingsSuccess } from '@actions/Common';
import WebRTCManager from '../../../util/WebRTC/WebRTCManager';

function* startObservations() {
  try {
    const admittedEndpoints = yield select(getAdmittedEndpoints);
    const arrAE = Object.values(admittedEndpoints);
    // const adminId = yield select(getAdminId);
    if (arrAE.length) {
      // the wait value is taken from the prev. version source code
      // setTimeout(() => {
      //   arrAE.forEach((item) => {
      //     WebRTCManager.directLink(`camera_${item.machine_name}`,
      //      `${adminId}`, { calibrateMotion: false });
      //   });
      // }, 1800);

      const startObservationActions = yield arrAE.map((item) => put(startRoomObservation(item)));
      yield all([...startObservationActions]);
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* endRoomObservation(data) {
  try {
    const { machine_name, id } = data;
    const params = {
      machine_name,
    };
    console.log('Start "end observation" for: ', machine_name);
    yield call(rest.endRoomObservation, params);

    const stopPlayAlertParams = {
      machine_name,
      command: CONTROL_COMMANDS.STOP_PLAY_ALERT,
      params_p: PARAMS_PER_COMMAND.stop_play_alert,
    };
    try {
      // To be redone when API Changes/Stabilizes from current Proxy Method
      yield call(rest.sendCommandByMachineName, stopPlayAlertParams);
    } catch (e) {
      console.warn('[Error] [send_command] sendCommandByMachineName endRoomObservation failed',
        JSON.stringify(stopPlayAlertParams), e);
    }

    const endObserverParams = {
      machine_name,
      command: CONTROL_COMMANDS.END_OBSERVER,
    };
    try {
      // To be redone when API Changes/Stabilizes from current Proxy Method
      yield call(rest.sendCommandByMachineName, endObserverParams);
    } catch (e) {
      console.warn('[Error] [send_command] endRoomObservation sendCommandByMachineName failed',
        JSON.stringify(endObserverParams), e);
    }
    yield put(endRoomObservationSuccess(id));

    console.log('end observation for: ', machine_name);
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* endObservations(adminId) {
  try {
    const removedEndpoints = yield select(getRemovedEndpoints);
    const arrRE = Object.values(removedEndpoints);
    if (arrRE.length) {
      const endObservationActions = yield arrRE.map((item) => {
        WebRTCManager.directUnlink(`camera_${item.machine_name}`, adminId, { calibrateMotion: false });
        return call(endRoomObservation, item);
      });
      yield all([...endObservationActions]);
      // WebRTCManager.closeUserSession();
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* updateObservations() {
  try {
    const changedEndpoints = yield select(getChangedEndpoints);

    if (changedEndpoints.length) {
      const updateObservationActions = yield changedEndpoints
        .map((item) => put(updateRoomObservation(item)));
      yield all([...updateObservationActions]);
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* applyChanges() {
  try {
    const admin_id = yield select(getAdminId);
    const sessId = yield select(getObservationSessionId);

    if (!sessId) {
      // 1 START SESSION
      WebRTCManager.closeUserSession();
      const sessionResponse = yield call(rest.startObservationSession, admin_id);
      yield put(startObservationSessionSuccess(sessionResponse));

      // 2 START OBSERVATION FOR ALL THE ADMITTED ENDPOINTS
      yield call(startObservations, admin_id);

      setTimeout(() => {
        WebRTCManager.login(`${admin_id}`);
      }, 1200);
    } else {
      // 1 END OBSERVATION SESSIONS
      const endObs = call(endObservations, admin_id);
      // 2 START OBSERVATION SESSIONS
      const startObs = call(startObservations, admin_id);
      // 3 UPDATE OBSERVATION SESSIONS
      const updateObs = call(updateObservations);
      // execute all in parallel
      yield all([endObs, startObs, updateObs]);
    }
    yield put(applyChangesSuccess({ status: 'updated' }));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* endObservationSession() {
  try {
    const applyiedEndpoints = yield select(getAppliedEndpoints);
    const removedEndpoints = yield select(getRemovedEndpoints);
    const arrAE = Object.values({ ...applyiedEndpoints, ...removedEndpoints });
    const adminId = yield select(getAdminId);

    if (arrAE.length && adminId) {
      const endObservationActions = yield arrAE.map((item) => {
        WebRTCManager.directUnlink(`camera_${item.machine_name}`, adminId, { calibrateMotion: false });
        return call(endRoomObservation, item);
      });
      yield all([...endObservationActions]);
    }
    WebRTCManager.closeUserSession();
    yield put(cleanUpSession());
    console.log('FINISH "END SESSION"');
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* cleanUpStoredSession() {
  try {
    const applyiedEndpoints = yield select(getAppliedEndpoints);
    const removedEndpoints = yield select(getRemovedEndpoints);
    const arrAE = Object.values({ ...applyiedEndpoints, ...removedEndpoints });
    const adminId = yield select(getAdminId);

    if (arrAE.length && adminId) {
      const endObservationActions = yield arrAE.map((item) => (
        WebRTCManager.directUnlink(`camera_${item.machine_name}`, adminId, { calibrateMotion: false })
        // return call(endRoomObservation, item);
      ));
      yield all([...endObservationActions]);
      WebRTCManager.closeUserSession();
      yield put(cleanUpSession());
      console.log('FINISH "CLEAN UP SESSION"');
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* logout() {
  try {
    yield call(endObservationSession);
    yield put(cleanUpDataOnLogout());
    console.log('FINAL LOGOUT STEP ............');
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* fetchIObserverControllerSettings() {
  try {
    const response = yield call(rest.getIObserverControllerSettings);
    if (response) {
      yield put(fetchIObserverControllerSettingsSuccess(response));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* refreshToken(timeToWait) {
  try {
    yield delay(timeToWait);
    const response = yield call(rest.refreshToken);
    if (response) {
      const { token_data } = response;
      // eslint-disable-next-line no-use-before-define
      yield call(handleTokenData, token_data);
    } else {
      yield put(initObservationResult(false));
    }
  } catch (error) {
    console.warn('[ERROR] refreshToken', error);
    yield put(initObservationResult(false, error.code));
    // yield put(fetchError(error));
  }
}

function* handleTokenData(token_data) {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(ACCESS_TOKEN_KEY, token_data.token);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, token_data.refresh_token);
    }

    if (token_data.expiration_time) {
      // 10 min earlier
      const diff = new Date(token_data.expiration_time - 10 * 60 * 1000).getTime()
        - Date.now();
      if (diff > 0) {
        yield fork(refreshToken, diff);
      }
    }
  } catch (error) {
    console.warn('[ERROR] handle token', error);
    // yield put(fetchError(error));
  }
}

function* initObservationHandler({ payload }) {
  try {
    const response = yield call(rest.init, payload);
    if (response) {
      const { token_data, data, endpoint } = response;
      const parsedData = new URLSearchParams(data);
      const dataToUse = {};
      Array.from(parsedData.keys()).forEach((key) => {
        dataToUse[key] = parsedData.get(key);
      });

      const { epic_client_id, esitter_controller_default } = response;

      yield put(fetchIObserverControllerSettingsSuccess({ value: esitter_controller_default }));
      yield put(initObservationResult(true, null, dataToUse, epic_client_id, endpoint));

      yield call(handleTokenData, token_data);
    } else {
      yield put(initObservationResult(false));
    }
  } catch (error) {
    console.warn('[ERROR] initToken', error);
    yield put(initObservationResult(false, error.code));
    // yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(APPLY_CHANGES, applyChanges);
  yield takeEvery(END_OBSERVATION_SESSION_REQUEST, endObservationSession);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(CLEAN_UP_SESSION_AFTER_TRANSFER, cleanUpStoredSession);
  yield takeEvery(START_OBSERVATIONS, startObservations);
  yield takeEvery(FETCH_IOBSERVER_CONTROLER_SETTINGS_REQUEST, fetchIObserverControllerSettings);
  yield takeEvery(INIT_OBSERVATION, initObservationHandler);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
  ]);
}

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import MonitoringLayout from './iObserverReducer/MonitoringLayoutReducer';
import Profile from './iObserverReducer/ProfileReducer';
import ListingsFilterReducers from './iObserverReducer/filters/ListingsFilterReducers';
import CustomerReducers from './iObserverReducer/CustomerReducers';
import EndpointReducers from './iObserverReducer/EndpointReducers';
import ObservationReducer from './iObserverReducer/ObservationReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  monitoringLayout: MonitoringLayout,
  profile: Profile,
  listingsFilter: ListingsFilterReducers,
  customers: CustomerReducers,
  endpoints: EndpointReducers,
  observation: ObservationReducer,
});

export default createRootReducer;

import { layoutNames } from '@constants/LayoutSettings';
import {
  CHANGE_LAYOUT,
} from '@constants/iObserverActionTypes';

const initialSettings = {
  selectedLayout: layoutNames.DEFAULT_LAYOUT,
};

const layout = (state = initialSettings, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        selectedLayout: action.payload.layout,
      };

    default:
      return state;
  }
};

export default layout;

export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const FETCH_OWN_PROFILE_REQUEST = 'FETCH_OWN_PROFILE_REQUEST';
export const FETCH_OWN_PROFILE_SUCCESS = 'FETCH_OWN_PROFILE_SUCCESS';
export const INIT_OBSERVATION = 'INIT_OBSERVATION';
export const INIT_OBSERVATION_RESULT = 'INIT_OBSERVATION_RESULT';

// Listings Filter Actions
export const LISTINGS_FILTER_GET_OPTIONS = 'LISTINGS_FILTER_GET_OPTIONS';
export const LISTINGS_FILTER_FETCH_SUCCESS = 'LISTINGS_FILTER_FETCH_SUCCESS';
export const RESET_LISTING_FILTERS = 'RESET_LISTING_FILTERS';

// Customers
export const CUSTOMERS_FETCH_DATA = 'CUSTOMERS_FETCH_DATA';
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS';
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';

// Endpoints
export const ENDPOINTS_FETCH_DATA = 'ENDPOINTS_FETCH_DATA';
export const ENDPOINTS_FETCH_SUCCESS = 'ENDPOINTS_FETCH_SUCCESS';
export const FETCH_ENDPOINT_REQUEST = 'FETCH_ENDPOINT_REQUEST';
export const FETCH_ENDPOINT_SUCCESS = 'FETCH_ENDPOINT_SUCCESS';

export const FETCH_PATIENT_RISKS_LIST_REQUEST = 'FETCH_PATIENT_RISKS_LIST_REQUEST';
export const FETCH_PATIENT_RISKS_LIST_SUCCESS = 'FETCH_PATIENT_RISKS_LIST_SUCCESS';
export const FETCH_OBSERVATION_RISKS_LIST_REQUEST = 'FETCH_OBSERVATION_RISKS_LIST_REQUEST';
export const FETCH_OBSERVATION_RISKS_LIST_SUCCESS = 'FETCH_OBSERVATION_RISKS_LIST_SUCCESS';

export const ADMIT_ENDPOINT = 'ADMIT_ENDPOINT';
export const REMOVE_ENDPOINT = 'REMOVE_ENDPOINT';

// Global
export const APPLY_CHANGES = 'APPLY_CHANGES';
export const APPLY_CHANGES_SUCCESS = 'APPLY_CHANGES_SUCCESS';

export const CHANGE_ENDPOINT_PROP = 'CHANGE_ENDPOINT_PROP';
export const SELECT_ADMITTED_ENDPOINT = 'SELECT_ADMITTED_ENDPOINT';

// Observation
export const START_OBSERVATION_SESSION_REQUEST = 'START_OBSERVATION_SESSION_REQUEST';
export const START_OBSERVATION_SESSION_SUCCESS = 'START_OBSERVATION_SESSION_SUCCESS';

export const START_ROOM_OBSERVATION_REQUEST = 'START_ROOM_OBSERVATION_REQUEST';
export const START_ROOM_OBSERVATION_SUCCESS = 'START_ROOM_OBSERVATION_SUCCESS';

export const UPDATE_ROOM_OBSERVATION_REQUEST = 'UPDATE_ROOM_OBSERVATION_REQUEST';
export const UPDATE_ROOM_OBSERVATION_SUCCESS = 'UPDATE_ROOM_OBSERVATION_SUCCESS';

export const END_ROOM_OBSERVATION_REQUEST = 'END_ROOM_OBSERVATION_REQUEST';
export const END_ROOM_OBSERVATION_SUCCESS = 'END_ROOM_OBSERVATION_SUCCESS';

export const SEND_COMMAND_BY_MACHINE_NAME = 'SEND_COMMAND_BY_MACHINE_NAME';

export const SELECT_OBSERVATION_ENDPOINT = 'SELECT_OBSERVATION_ENDPOINT';

export const UPDATE_NIGHT_VIEW = 'UPDATE_NIGHT_VIEW';
export const REFRESH_CAMERA_ACTION = 'REFRESH_CAMERA_ACTION';

export const START_OBSERVATION_ACTIVITY = 'START_OBSERVATION_ACTIVITY';
export const END_OBSERVATION_ACTIVITY = 'END_OBSERVATION_ACTIVITY';

export const SET_LISTEN = 'SET_LISTEN';
export const SET_TALK = 'SET_TALK';
export const SET_NOTIFY = 'SET_NOTIFY';

export const FETCH_LISTEN_REASONS_REQUEST = 'FETCH_LISTEN_REASONS_REQUEST';
export const FETCH_LISTEN_REASONS_SUCCESS = 'FETCH_LISTEN_REASONS_SUCCESS';

export const FETCH_TALK_REASONS_REQUEST = 'FETCH_TALK_REASONS_REQUEST';
export const FETCH_TALK_REASONS_SUCCESS = 'FETCH_TALK_REASONS_SUCCESS';

export const FETCH_ALERT_REASONS_REQUEST = 'FETCH_ALERT_REASONS_REQUEST';
export const FETCH_ALERT_REASONS_SUCCESS = 'FETCH_ALERT_REASONS_SUCCESS';

export const FETCH_ALL_REASONS_REQUEST = 'FETCH_ALL_REASONS_REQUEST';
export const FETCH_ALL_REASONS_SUCCESS = 'FETCH_ALL_REASONS_SUCCESS';

export const START_NOTIFY = 'START_NOTIFY';
export const STOP_NOTIFY = 'STOP_NOTIFY';

export const END_OBSERVATION_SESSION_REQUEST = 'END_OBSERVATION_SESSION_REQUEST';
export const END_OBSERVATION_SESSION_SUCCESS = 'END_OBSERVATION_SESSION_SUCCESS';
export const CLEAN_UP_SESSION = 'CLEAN_UP_SESSION';
export const LOGOUT = 'LOGOUT';
export const CLEAN_UP_DATA_ON_LOGOUT = 'CLEAN_UP_DATA_ON_LOGOUT';
export const FETCH_ENDPOINT_DETAILS = 'FETCH_ENDPOINT_DETAILS';
export const UPDATE_ENDPOINT_DETAILS = 'UPDATE_ENDPOINT_DETAILS';

export const TRANSFER_SESSION_REQUEST = 'TRANSFER_SESSION_REQUEST';
export const TRANSFER_SESSION_SUCCESS = 'TRANSFER_SESSION_SUCCESS';

export const CANCEL_TRANSFER_SESSION_REQUEST = 'CANCEL_TRANSFER_SESSION_REQUEST';
export const CANCEL_TRANSFER_SESSION_SUCCESS = 'CANCEL_TRANSFER_SESSION_SUCCESS';

export const CLEAN_UP_SESSION_AFTER_TRANSFER = 'CLEAN_UP_SESSION_AFTER_TRANSFER';
export const LOAD_IMPORTED_ENDPOINTS = 'LOAD_IMPORTED_ENDPOINTS';
export const INITIALIZE_TRANSFER_SESSION_WS_CHANNEL = 'INITIALIZE_TRANSFER_SESSION_WS_CHANNEL';
export const TRANSFER_SESSION_WS_MESSAGE_RECEIVED = 'TRANSFER_SESSION_WS_MESSAGE_RECEIVED';
export const SEND_COMMAND_BY_USER = 'SEND_COMMAND_BY_USER';

export const GET_TRANSFERS_REQUEST = 'GET_TRANSFERS_REQUEST';
export const GET_TRANSFERS_SUCCESS = 'GET_TRANSFERS_SUCCESS';
export const TAKE_OVER_SESSION = 'TAKE_OVER_SESSION';
export const START_IMPORT_SESSION = 'START_IMPORT_SESSION';
export const START_OBSERVATIONS = 'START_OBSERVATIONS';
export const TRANSFER_COMPLETE = 'TRANSFER_COMPLETE';
export const SET_SESSION_POPUP_MESSAGE = 'SET_SESSION_POPUP_MESSAGE';

export const GET_AUDIO_VIDEO_REQUEST = 'GET_AUDIO_VIDEO_REQUEST';
export const GET_AUDIO_VIDEO_SUCCESS = 'GET_AUDIO_VIDEO_SUCCESS';
export const SET_AUDIO_VIDEO_BLOCKED = 'SET_AUDIO_VIDEO_BLOCKED';

export const SET_AUDIO_VIDEO_REQUEST = 'SET_AUDIO_VIDEO_REQUEST';
export const SET_AUDIO_VIDEO_SUCCESS = 'SET_AUDIO_VIDEO_SUCCESS';

export const FETCH_IOBSERVER_CONTROLER_SETTINGS_REQUEST = 'FETCH_IOBSERVER_CONTROLER_SETTINGS_REQUEST';
export const FETCH_IOBSERVER_CONTROLER_SETTINGS_SUCCESS = 'FETCH_IOBSERVER_CONTROLER_SETTINGS_SUCCESS';

export const GET_ENDPOINT_STATUS_REQUEST = 'GET_ENDPOINT_STATUS_REQUEST';
export const GET_ENDPOINT_STATUS_SUCCESS = 'GET_ENDPOINT_STATUS_SUCCESS';

export const CHECK_ENDPOINT_ACTION = 'CHECK_ENDPOINT_ACTION';
export const CHECK_ENDPOINT_RESULT_ACTION = 'CHECK_ENDPOINT_RESULT_ACTION';
export const UPDATE_ENDPOINT_MEDIA_TYPE = 'UPDATE_ENDPOINT_MEDIA_TYPE';

import {
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
} from '@constants/iObserverActionTypes';

export const getProfile = () => ({
  type: FETCH_OWN_PROFILE_REQUEST,
});
export const getProfileSuccess = (payload = {}) => ({
  type: FETCH_OWN_PROFILE_SUCCESS,
  payload,
});

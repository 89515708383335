import { cloneDeep } from 'lodash';
import {
  CUSTOMERS_FETCH_SUCCESS,
  CUSTOMERS_FETCH_DATA,
  FETCH_CUSTOMER_SUCCESS,
} from '@constants/iObserverActionTypes';

const INIT_CUSTOMER_STATE = {
  loading: false,
  list: [],
  page: {},
  selectedCustomer: {

  },
};

/**
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = cloneDeep(INIT_CUSTOMER_STATE), action) => {
  let newState = null;
  switch (action.type) {
    case CUSTOMERS_FETCH_SUCCESS:
      newState = {
        list: { ...action.data.list },
        loading: false,
      };

      return { ...state, ...newState };

    case CUSTOMERS_FETCH_DATA:
      return { ...state, ...{ loading: true } };

    case FETCH_CUSTOMER_SUCCESS:
      const selectedCustomer = {
        ...action.payload,
      };
      newState = {
        selectedCustomer,
        loading: false,
      };
      return { ...state, ...newState };

    default:
      return state;
  }
};

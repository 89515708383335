import { eventChannel } from 'redux-saga';
import {
  put,
  call,
  take,
  select,
  takeEvery,
  all,
  fork,
  delay,
} from 'redux-saga/effects';

import {
  CONTROL_COMMANDS,
  SESSION_MESSAGES,
} from '@constants/Settings';

import {
  INITIALIZE_TRANSFER_SESSION_WS_CHANNEL,
  TRANSFER_SESSION_WS_MESSAGE_RECEIVED,

} from '@constants/iObserverActionTypes';

import {
  loadImportedSession,
  sendCommandByUser,
  onTransferSessionWsMessageReceived,
  transferComplete,
  setPopUpMessage,
} from '@actions/iObserverActions/SessionActions';

import {
  startObservations,
  endSession,
} from '@actions/iObserverActions/ObservationActions';

import {
  getProfile,
  getAppliedEndpoints,
  getRemovedEndpoints,
} from '@selectors/iObserverSelectors';

import ActionCable2 from '@util/ws/action_cable';
import { getTransferId } from '../../selectors/iObserverSelectors';

const RECEIVED_MESSAGE_COMMANDS = {
  request_transfer_info: 'request_transfer_info',
  transfer_info: 'transfer_info',
  transfer_complete: 'transfer_complete',
};

function* prepareTransferSessionInfo(r_email) {
  const arrData = [];
  const appliedEndpoints = yield select(getAppliedEndpoints);
  const removedEndpoints = yield select(getRemovedEndpoints);
  const videoSelectedEndpoints = { ...appliedEndpoints, ...removedEndpoints };

  if (Object.keys(videoSelectedEndpoints).length) {
    Object.keys(videoSelectedEndpoints).forEach((key) => {
      const obj = videoSelectedEndpoints[key];
      const newInfoObj = {
        id: obj.id || '',
        name: obj.name || '',
        machine_name: obj.machine_name,
        priority: obj.patientInfo.risk_priority || '',
        use_profile: true,
        profile: obj.patientInfo.risk_type || '',
        use_name: true,
        patient_name: obj.patientInfo.patient_name || '',
        use_bed_name: true,
        bed_name: obj.patientInfo.bed_name || '',
        use_notes: true,
        notes: obj.patientInfo.notes || '',
        use_notification_contacts: true,
        notification_contacts: obj.patientInfo.notification_contacts || [],
        ordering_idx: obj.ordering_idx,
      };
      arrData.push(newInfoObj);
    });
  }

  const infoObject = {
    transfer_info: arrData,
    r_email,
  };
  console.log('Prepared transfer info data: ', infoObject);
  return infoObject;
}

function createEventChannel(mySocket, user_id) {
  return eventChannel((emit) => {
    mySocket.subscriptions.create({
      channel: 'UserChannel',
      user_id: `obs_${user_id}`,
    }, {
      connected: () => {
        console.log('connected to the user tunnel');
      },
      disconnected: () => {
        console.log('disconnected from the user tunnel');
      },
      received: (data) => {
        console.log(`response received from user tunnel: ${JSON.stringify(data)}`);
        return emit(data);
      },
      send_command(command) {
        return this.perform('send_command', {
          command,
        });
      },
    });
    return () => {
      mySocket.disconnect();
    };
  });
}

let socket = null;
function* initializeWebSocketsChannel() {
  if (!socket) {
    console.log('INIT WS==========================================================================================================================');
    try {
      const profile = yield select(getProfile);
      const user_id = profile.profile.username;
      socket = ActionCable2.createConsumer(process.env.REACT_APP_WS_ACTION_CABLE_URL);
      console.log('WS AC : ', process.env.REACT_APP_WS_ACTION_CABLE_URL);
      const channel = yield call(createEventChannel, socket, user_id);
      while (true) {
        const message = yield take(channel);
        yield put(onTransferSessionWsMessageReceived(message));
      }
    } catch (err) {
      console.error('INITIALIZE_TRANSFER_SESSION_WS_CHANNEL ERROR:: ', err);
    }
  } else {
    console.warn('INITIALIZE_TRANSFER_SESSION_WS_CHANNEL SOCKET ALREADY EXISTS');
  }
}

// let r_email = '';

function* handleTransferInfoMessage(message) {
  const { params } = message;
  const { r_email, transfer_info } = params;
  if (params && (params instanceof Object)) {
    if (r_email && transfer_info && transfer_info.length) {
      // set new transfer data to the store
      yield put(loadImportedSession(transfer_info));
      yield delay(1000);
      yield put(startObservations());
      yield put(setPopUpMessage(SESSION_MESSAGES.SESSION_IMPORTED));
      yield delay(2000);
      yield put(transferComplete(r_email));
    }
  }
}

function* handleTransferInfoRequestMessage(message) {
  const transferId = yield select(getTransferId);
  if (!transferId || transferId === -1) {
    console.log('[transfer] Transfer request info received, but not transferring', transferId);
    return;
  }
  const userProfile = yield select(getProfile);
  const email = userProfile.profile.username;
  const preparedSessionInfo = yield call(prepareTransferSessionInfo, email);

  const params = {
    email: message.params.r_email,
    command: CONTROL_COMMANDS.TRANSFER_INFO,
    params_p: { ...preparedSessionInfo },
  };
  yield put(sendCommandByUser(params));
}

function* onMessageReceived(data) {
  console.log('Saga WS Message data: ', data);
  try {
    const { message } = data.payload;

    if (message && message.command) {
      switch (message.command) {
        case RECEIVED_MESSAGE_COMMANDS.request_transfer_info:
          yield handleTransferInfoRequestMessage(message);
          break;

        case RECEIVED_MESSAGE_COMMANDS.transfer_info:
          yield handleTransferInfoMessage(message);
          break;

        case RECEIVED_MESSAGE_COMMANDS.transfer_complete:
          yield put(endSession());
          yield put(setPopUpMessage(SESSION_MESSAGES.SESSION_END));
          break;

        default:
          console.log('Unknown message response: ', message);
          break;
      }
    }
  } catch (err) {
    console.error('INITIALIZE_TRANSFER_SESSION_WS_CHANNEL ERROR:: ', err);
  }
}

export function* actionsWatcher() {
  yield takeEvery(INITIALIZE_TRANSFER_SESSION_WS_CHANNEL, initializeWebSocketsChannel);
  yield takeEvery(TRANSFER_SESSION_WS_MESSAGE_RECEIVED, onMessageReceived);
  // yield takeEvery('TEST', test);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
  ]);
}

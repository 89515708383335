import {
  APPLY_CHANGES,
  APPLY_CHANGES_SUCCESS,
  LOGOUT,
  CLEAN_UP_DATA_ON_LOGOUT, INIT_OBSERVATION, INIT_OBSERVATION_RESULT,
} from '@constants/iObserverActionTypes';

/**
 * @return {Object}
 */
export const applyChanges = () => ({
  type: APPLY_CHANGES,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const applyChangesSuccess = (payload) => ({
  type: APPLY_CHANGES_SUCCESS,
  payload,
});

/**
 * @return {Object}
 */
export const logout = () => ({
  type: LOGOUT,
});

/**
 * @return {Object}
 */
export const cleanUpDataOnLogout = () => {
  console.log('CLEAN_UP_DATA_ON_LOGOUT ACTION');
  return {
    type: CLEAN_UP_DATA_ON_LOGOUT,
  };
};

export const initObservation = ({ api_username, data }) => ({
  type: INIT_OBSERVATION,
  payload: {
    api_username,
    data,
  },
});

export const initObservationResult = (success = null, errorCode = null, data = null,
  epic_client_id = null, endpoint = null) => ({
  type: INIT_OBSERVATION_RESULT,
  payload: {
    success,
    errorCode,
    data,
    epic_client_id,
    endpoint,
  },
});

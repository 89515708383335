import { all } from 'redux-saga/effects';
import profileSagas from './ProfileSaga';
import listingsFilterSagas from './iObserverSagas/filters/ListingsFilterSaga';
import customerSagas from './iObserverSagas/CustomerSaga';
import endpointSagas from './iObserverSagas/EndpointSaga';
import globalSagas from './iObserverSagas/GlobalSaga';
import observationSagas from './iObserverSagas/ObservationSaga';
import observationSessionSagas from './iObserverSagas/ObservationSessionSaga';
import transferSessionWsSaga from './iObserverSagas/TransferSessionWsSaga';

export default function* rootSaga(getState) {
  yield all([
    profileSagas(),
    listingsFilterSagas(),
    customerSagas(),
    endpointSagas(),
    globalSagas(),
    observationSagas(),
    observationSessionSagas(),
    transferSessionWsSaga(),
  ]);
}

import {
  all, call, fork, put, takeEvery, select, take,
} from 'redux-saga/effects';
import WebRTCManager from '@util/WebRTC/WebRTCManager';
import WebRTCUtils from '@util/WebRTC/WebRTCUtils';
import {
  startObservationSessionSuccess,
  startRoomObservationSuccess,
  updateRoomObservationSuccess,
  endRoomObservationSuccess,
  goToBookmark,
  stopPlayAlert,
  endObserver,
  updateNightView,
  fetchListenReasonsSuccess,
  fetchTalkReasonsSuccess,
  fetchNotifyReasonsSuccess,
  setListen,
  setTalk,
  setNotify,
  getAudioVideoSuccess,
  setAudioVideoSuccess, checkEndpointResultAction, setAudioVideoBlocked, stopNotifyPlayAlert,
  setAudioVideoRequest,
} from '@actions/iObserverActions/ObservationActions';
import {
  START_OBSERVATION_SESSION_REQUEST,
  START_ROOM_OBSERVATION_REQUEST,
  UPDATE_ROOM_OBSERVATION_REQUEST,
  END_ROOM_OBSERVATION_REQUEST,
  SEND_COMMAND_BY_MACHINE_NAME,
  START_OBSERVATION_ACTIVITY,
  END_OBSERVATION_ACTIVITY,
  FETCH_LISTEN_REASONS_REQUEST,
  FETCH_TALK_REASONS_REQUEST,
  FETCH_ALERT_REASONS_REQUEST,
  FETCH_ALL_REASONS_REQUEST,
  START_NOTIFY,
  REFRESH_CAMERA_ACTION,
  GET_AUDIO_VIDEO_REQUEST,
  SET_AUDIO_VIDEO_REQUEST,
  CHECK_ENDPOINT_ACTION,
  SET_AUDIO_VIDEO_BLOCKED,
} from '@constants/iObserverActionTypes';

import {
  getObservationSessionId, getAdminId, getEndpointByMachineName,
  getAudioVideo, getObservationInitResult,
} from '@selectors/iObserverSelectors';

import {
  CONTROL_COMMANDS,
  OBSERVATION_ACTIVITY,
} from '@constants/Settings';

import { rest } from '@api/iObserverApi';
import { fetchError } from '@actions/Common';
import ActionCable from '@util/ws/action_cable';
import { eventChannel } from 'redux-saga';
import {
  ENDPOINT_STATUS_OFFLINE, ENDPOINT_STATUS_ONLINE,
} from '@constants/EndpointAvailabilityStatusTypes';

function* startSession(data) {
  try {
    const { admin_id } = data.payload;
    const sessionResponse = yield call(rest.startObservationSession, admin_id);
    // {"observation_session_id":7047}
    yield put(startObservationSessionSuccess(sessionResponse));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* startRoomObservation(data) {
  try {
    const sessId = yield select(getObservationSessionId);
    const { machine_name, patientInfo, id } = data.payload;
    const {
      bed_name,
      notes,
      notification_contacts,
      patient_name,
      risk_priority,
      risk_type,
    } = { ...patientInfo, ...patientInfo.dirtyProps };

    const params = {
      observation_session_id: sessId,
      machine_name,
      risk_priority,
      risk_type,
      notes,
      bed_name,
      patient_name,
      location_desc: '',
      notification_contacts,
    };
    const adminId = yield select(getAdminId);
    yield put(goToBookmark(machine_name));
    const startObservationResponse = yield call(rest.startRoomObservation, params);
    yield put(startRoomObservationSuccess(startObservationResponse, id));
    WebRTCManager.directLink(`camera_${machine_name}`, `${adminId}`, { calibrateMotion: false });
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* endRoomObservation(data) {
  try {
    const { machine_name, id } = data.payload;

    const params = {
      machine_name,
    };
    yield console.log('Start "end observation" for: ', machine_name);
    yield call(rest.endRoomObservation, params);
    yield put(stopPlayAlert(machine_name));
    yield put(endObserver(machine_name));
    yield put(endRoomObservationSuccess(id));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* updateRoomObservation(data) {
  try {
    const {
      machine_name, patientInfo, id,
    } = data.payload;
    const {
      bed_name,
      notes,
      notification_contacts,
      patient_name,
      risk_priority,
      risk_type,
    } = { ...patientInfo, ...patientInfo.dirtyProps };

    const params = {
      machine_name,
      risk_priority,
      risk_type,
      notes,
      bed_name,
      patient_name,
      // location_desc: "",
      notification_contacts,
    };

    const updateObservationResponse = yield call(rest.updateRoomObservation, params);
    // {"observation_session_id":7047}
    yield put(updateRoomObservationSuccess(updateObservationResponse, id));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* sendCommandByMachineName(data) {
  try {
    const params = { ...data.payload };

    if (params.command === CONTROL_COMMANDS.NIGHT_VIEW) {
      yield put(updateNightView(params.machine_name, data.payload.params_p.on));
    }
    // To be redone when API Changes/Stabilizes from current Proxy Method
    const initResult = yield select(getObservationInitResult);
    if (initResult?.data) {
      yield call(rest.sendCommandByMachineNameMonitor, params);
    } else {
      yield call(rest.sendCommandByMachineName, params);
    }
  } catch (error) {
    console.warn('[Error] [send_command] sendCommandByMachineName', error, data);
    yield put(fetchError(error));
  }
}

function* handleObservstionActivity(machine_name, type, toSet) {
  if (type === OBSERVATION_ACTIVITY.TYPES.LISTEN) {
    yield put(setListen(machine_name, toSet));
  } else if (type === OBSERVATION_ACTIVITY.TYPES.TALK) {
    if (toSet) {
      WebRTCManager.startCall(`camera_${machine_name}`, 'audioCall');
    } else {
      WebRTCManager.endCall(`camera_${machine_name}`);
    }

    yield put(setTalk(machine_name, toSet));
  } else if (type === OBSERVATION_ACTIVITY.TYPES.ALERT) {
    yield put(setNotify(machine_name, toSet));

    if (!toSet) {
      yield put(stopNotifyPlayAlert(machine_name));
    }
  }
}

function* startObservationActivity(data) {
  const params = { ...data.payload.params };
  const { toSet } = data.payload;
  try {
    yield call(handleObservstionActivity, params.machine_name, params.a_type, toSet);

    const startObservationActivityResponse = yield call(rest.startObservationActivity, params);
    if (!startObservationActivityResponse
      || !startObservationActivityResponse.observationActivityId) {
      yield call(handleObservstionActivity, params.machine_name, params.a_type, !toSet);
    }
  } catch (error) {
    yield call(handleObservstionActivity, params.machine_name, params.a_type, !toSet);

    console.warn('startObservationActivity', error, data);
    yield put(fetchError(error));
  }
}

function* endObservationActivity(data) {
  try {
    const params = { ...data.payload.params };

    if (params.a_type === OBSERVATION_ACTIVITY.TYPES.LISTEN) {
      yield put(setListen(params.machine_name, data.payload.toSet));
    } else if (params.a_type === OBSERVATION_ACTIVITY.TYPES.TALK) {
      if (data.payload.setOnly) {
        WebRTCManager.endCall(`camera_${params.machine_name}`);
      }

      yield put(setTalk(params.machine_name, data.payload.toSet));
    } else if (params.a_type === OBSERVATION_ACTIVITY.TYPES.ALERT) {
      yield put(setNotify(params.machine_name, data.payload.toSet));
    }

    if (!data.payload.setOnly) {
      yield call(rest.endObservationActivity, params);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* refreshCameraHandler({ payload }) {
  try {
    const { machine_name } = payload;
    const params = {
      machine_name,
      command: CONTROL_COMMANDS.REATTACH_VIDEO,
      params_p: {},
    };

    try {
      // To be redone when API Changes/Stabilizes from current Proxy Method
      yield call(rest.sendCommandByMachineName, params);
    } catch (e) {
      console.warn('[Error] [send_command] refreshCameraHandler sendCommandByMachineName failed',
        JSON.stringify(params), e);
    }
    yield call(rest.sendRefreshCamera, { machine_name });
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* sendAlertTextMessages(machine_name) {
  try {
    const endpointData = yield select(getEndpointByMachineName, machine_name);

    if (endpointData && endpointData.patientInfo && endpointData.patientInfo.notification_contacts
      && endpointData.patientInfo.notification_contacts.length) {
      const data = { machine_name };

      data.bed_name = endpointData.patientInfo.bed_name || endpointData.patientInfo.patient_name
        || endpointData.name;
      const phoneNumbers = endpointData.patientInfo.notification_contacts;
      for (let i = 0; i < phoneNumbers.length; i += 1) {
        phoneNumbers[i].phone_number = phoneNumbers[i].phone_number.replaceAll('-', '');
      }
      data.recipients = phoneNumbers;

      yield call(rest.sendAlertTextMessage, data);
    }
  } catch (error) {
    console.warn('[OS] send alert notif', error);
    yield put(fetchError(error));
  }
}

function* startNotify(data) {
  const { playAlertParams, startActivityParams } = data.payload;
  try {
    yield call(handleObservstionActivity, startActivityParams.machine_name,
      startActivityParams.a_type, true);

    yield call(sendAlertTextMessages, startActivityParams.machine_name);
    yield call(sendCommandByMachineName, { payload: { ...playAlertParams } });
    const obActivityData = {
      payload: {
        params: {
          a_type: startActivityParams.a_type,
          machine_name: startActivityParams.machine_name,
          reason: startActivityParams.reason,
        },
        toSet: startActivityParams.toSet,
      },
    };
    yield call(startObservationActivity, obActivityData);
  } catch (error) {
    console.warn('[OS] startNotify', error);
    yield call(handleObservstionActivity, startActivityParams.machine_name,
      startActivityParams.a_type, false);
    yield put(fetchError(error));
  }
}

function* fetchListenReasons() {
  try {
    const listenReasonsResponse = yield call(rest.fetchListenReasons);
    if (listenReasonsResponse) {
      yield put(fetchListenReasonsSuccess(listenReasonsResponse));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchTalkReasons() {
  try {
    const talkReasonsResponse = yield call(rest.fetchTalkReasons);
    if (talkReasonsResponse) {
      yield put(fetchTalkReasonsSuccess(talkReasonsResponse));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchNotifyReasons() {
  try {
    const notifyReasonsResponse = yield call(rest.fetchNotifyReasons);
    if (notifyReasonsResponse) {
      yield put(fetchNotifyReasonsSuccess(notifyReasonsResponse));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchAllReasons() {
  const listenReasons = call(fetchListenReasons);
  const talkReasons = call(fetchTalkReasons);
  const notifyReasons = call(fetchNotifyReasons);
  // execute all in parallel
  yield all([listenReasons, talkReasons, notifyReasons]);
}

function* constructConstraintsForWebRtc(audioVideoServerResponse) {
  const devices = yield navigator.mediaDevices.enumerateDevices();
  // const noCameraOption = {
  //   deviceId: 'No Camera',
  //   kind: 'videoinput',
  //   label: 'No Camera',
  // };

  // devices.push(noCameraOption);

  const { audio, video } = audioVideoServerResponse;
  let hasSameVideo = null;
  let hasSameAudio = null;

  let videoToSet = null;
  let audioToSet = null;

  if (video) {
    hasSameVideo = devices.find((localdevice) => localdevice.kind === 'videoinput' && localdevice.label === video);
  }
  if (audio) {
    hasSameAudio = devices.find((localdevice) => localdevice.kind === 'audioinput' && localdevice.label === audio);
  }

  if (hasSameVideo) {
    videoToSet = hasSameVideo;
  } else {
    [videoToSet] = devices.filter((x) => x.kind === 'videoinput');
  }
  if (hasSameAudio) {
    audioToSet = hasSameAudio;
  } else {
    [audioToSet] = devices.filter((x) => x.kind === 'audioinput');
  }

  const videoConstraints = {
    deviceId: videoToSet.deviceId,
    label: videoToSet.label,
  };
  const audioConstraints = {
    deviceId: audioToSet.deviceId,
    label: audioToSet.label,
  };

  return { audio: audioConstraints, video: videoConstraints };
}

function setStreamToTheWebRTC(constraints) {
  let isSafari = false;
  if (navigator.userAgent.indexOf('Safari') !== -1
    && navigator.userAgent.indexOf('Chrome') === -1
    && navigator.userAgent.indexOf('Chromium') === -1) {
    isSafari = true;
  }

  const preparedConstraints = WebRTCUtils.formatMediaConstraints(
    constraints.audio.deviceId,
    constraints.video.deviceId,
    isSafari,
  );

  WebRTCManager.startLocalStream(preparedConstraints);
}
function* handleStreamToTheWebRTC(audioVideoServerResponse) {
  try {
    //  1 test permissions
    const testConstraints = { audio: true, video: true };
    const stream = yield navigator.mediaDevices.getUserMedia(testConstraints);

    if (stream) {
      stream.getAudioTracks()[0].stop();
      stream.getVideoTracks()[0].stop();
    }
    //  2 get constraints for webRtc
    const constraints = yield constructConstraintsForWebRtc(audioVideoServerResponse);

    //  3 set webrtc stream
    yield setStreamToTheWebRTC(constraints);
  } catch (e) {
    console.error('ERROR handleStreamToTheWebRTC :::: ', e);
  }
}

function* getDeviceId() {
  try {
    const audioVideo = yield select(getAudioVideo);
    if (!audioVideo.isMicBlocked && audioVideo.speaker) {
      const devices = yield navigator.mediaDevices.enumerateDevices();
      const speakerDevices = devices.filter((x) => x.kind === 'audiooutput');
      const currentSpeaker = speakerDevices.find((x) => x.label === audioVideo.speaker);
      const payload = {
        ...audioVideo,
        speakerId: currentSpeaker?.deviceId,
      };
      yield put(setAudioVideoRequest(payload));
    }
  } catch (e) {
    console.log('ERROR not found deviceId', e);
  }
}

function* getAudioVideoSaga() {
  try {
    const audioVideoResponse = yield call(rest.getAudioVideo);
    yield put(getAudioVideoSuccess(audioVideoResponse));
    yield call(handleStreamToTheWebRTC, audioVideoResponse);
    yield call(getDeviceId);
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* setAudioVideo(data) {
  try {
    const { payload } = data;
    const audioVideoResponse = yield call(rest.setAudioVideo, payload);
    if (audioVideoResponse.success === true) {
      yield put(setAudioVideoSuccess(payload));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function createEventChannel({ socket, machine_name }) {
  return eventChannel((emit) => {
    let waitTimeout;
    const tunnel = socket.subscriptions.create({
      channel: 'TunnelChannel',
      machine_name,
    }, {
      connected: () => {
        console.log('[EventChannel] connected to the TunnelChannel');
        tunnel.send_command({ command: 'is_in_obs_call'/* , meta: {} */ });
        waitTimeout = setTimeout(() => {
          if (socket && socket.connection && socket.connection.monitor
            && socket.connection.monitor.pingedAt) {
            console.log('[EventChannel] ping received while waiting on the TunnelChannel');
            emit(ENDPOINT_STATUS_ONLINE);
          } else {
            emit(ENDPOINT_STATUS_OFFLINE);
          }
        }, 5000);
      },
      disconnected: () => {
        console.log('[EventChannel] disconnected from the TunnelChannel');
        return emit(ENDPOINT_STATUS_OFFLINE);
      },
      received: (data) => {
        console.log(`[EventChannel] response received from TunnelChannel: ${JSON.stringify(data)}`);
        return emit(ENDPOINT_STATUS_ONLINE);
      },
      send_command(command) {
        return this.perform('send_command', {
          command,
        });
      },
    });
    return () => {
      clearTimeout(waitTimeout);
      if (tunnel) {
        tunnel.unsubscribe();
      }
      socket.disconnect();
    };
  });
}

function* checkEndpointActive({ payload }) {
  try {
    const socket = ActionCable.createConsumer(process.env.REACT_APP_WS_ACTION_CABLE_URL);
    console.log('WS AC 2: ', process.env.REACT_APP_WS_ACTION_CABLE_URL);
    const { machine_name } = payload;
    // yield call(rest.esitterToggle, { machine_name, value: false });
    // yield call(rest.esitterToggle, { machine_name, value: true });
    const channel = yield call(createEventChannel, { socket, machine_name });
    const availabilityStatus = yield take(channel);
    yield put(checkEndpointResultAction(machine_name, availabilityStatus));
    channel.close();
  } catch (err) {
    console.error('INITIALIZE_TRANSFER_SESSION_WS_CHANNEL ERROR:: ', err);
  }
}

export function* actionsWatcher() {
  yield takeEvery(START_OBSERVATION_SESSION_REQUEST, startSession);
  yield takeEvery(START_ROOM_OBSERVATION_REQUEST, startRoomObservation);
  yield takeEvery(END_ROOM_OBSERVATION_REQUEST, endRoomObservation);
  yield takeEvery(UPDATE_ROOM_OBSERVATION_REQUEST, updateRoomObservation);
  yield takeEvery(SEND_COMMAND_BY_MACHINE_NAME, sendCommandByMachineName);
  yield takeEvery(START_OBSERVATION_ACTIVITY, startObservationActivity);
  yield takeEvery(END_OBSERVATION_ACTIVITY, endObservationActivity);
  yield takeEvery(REFRESH_CAMERA_ACTION, refreshCameraHandler);
  yield takeEvery(FETCH_LISTEN_REASONS_REQUEST, fetchListenReasons);
  yield takeEvery(FETCH_TALK_REASONS_REQUEST, fetchTalkReasons);
  yield takeEvery(FETCH_ALERT_REASONS_REQUEST, fetchNotifyReasons);
  yield takeEvery(FETCH_ALL_REASONS_REQUEST, fetchAllReasons);
  yield takeEvery(START_NOTIFY, startNotify);
  yield takeEvery(GET_AUDIO_VIDEO_REQUEST, getAudioVideoSaga);
  yield takeEvery(SET_AUDIO_VIDEO_REQUEST, setAudioVideo);
  yield takeEvery(CHECK_ENDPOINT_ACTION, checkEndpointActive);
  yield takeEvery(SET_AUDIO_VIDEO_BLOCKED, getDeviceId);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
  ]);
}

import {
  ENDPOINTS_FETCH_DATA,
  ENDPOINTS_FETCH_SUCCESS,
  FETCH_ENDPOINT_REQUEST,
  FETCH_ENDPOINT_SUCCESS,
  ADMIT_ENDPOINT,
  REMOVE_ENDPOINT,
  CHANGE_ENDPOINT_PROP,
  SELECT_ADMITTED_ENDPOINT,
  FETCH_PATIENT_RISKS_LIST_REQUEST,
  FETCH_PATIENT_RISKS_LIST_SUCCESS,
  FETCH_OBSERVATION_RISKS_LIST_REQUEST,
  FETCH_OBSERVATION_RISKS_LIST_SUCCESS,
  FETCH_ENDPOINT_DETAILS,
  UPDATE_ENDPOINT_DETAILS,
  GET_ENDPOINT_STATUS_REQUEST,
  GET_ENDPOINT_STATUS_SUCCESS,
} from '@constants/iObserverActionTypes';

/**
 * @param  {number} page
 * @param  {string} sorting
 * @return {Object}
 */
export const endpointsOnFetchData = (page, sorting, filter) => ({
  type: ENDPOINTS_FETCH_DATA,
  page,
  sorting,
  filter,
});

/**
 * @param  {Object} data
 * @return {Object}
 */
export const endpointsFetchDataSuccess = (data) => ({
  type: ENDPOINTS_FETCH_SUCCESS,
  data,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchEndpoint = (payload) => ({
  type: FETCH_ENDPOINT_REQUEST,
  id: payload.id,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const fetchEndpointSuccess = (payload) => ({
  type: FETCH_ENDPOINT_SUCCESS,
  payload,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const admitEndpoint = (payload) => ({
  type: ADMIT_ENDPOINT,
  payload,
});
/**
 * @param  {Object} payload
 * @return {Object}
 */
export const removeEndpoint = (payload) => ({
  type: REMOVE_ENDPOINT,
  payload,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const changeEndpointProp = (payload) => ({
  type: CHANGE_ENDPOINT_PROP,
  payload,
});

/**
 * @param  {Object} payload
 * @return {Object}
 */
export const selectAdmittedEndpoint = (id) => ({
  type: SELECT_ADMITTED_ENDPOINT,
  payload: { id },
});

/**
 * @return {Object}
 */
export const fetchPatientRisksList = () => ({
  type: FETCH_PATIENT_RISKS_LIST_REQUEST,
});
/**
 * @param  {Array} riskList
 * @return {Object}
 */
export const fetchPatientRisksListSuccess = (risksList) => ({
  type: FETCH_PATIENT_RISKS_LIST_SUCCESS,
  payload: risksList,
});

/**
 * @return {Object}
 */
export const fetchObservationRisksList = () => ({
  type: FETCH_OBSERVATION_RISKS_LIST_REQUEST,
});
/**
 * @param  {Array} riskList
 * @return {Object}
 */
export const fetchObservationRisksListSuccess = (risksList) => ({
  type: FETCH_OBSERVATION_RISKS_LIST_SUCCESS,
  payload: risksList,
});

export const fetchEndpointDetails = (machine_name = null) => ({
  type: FETCH_ENDPOINT_DETAILS,
  payload: {
    machine_name,
  },
});

export const updateEndpointDetails = (machine_name = null, details = null) => ({
  type: UPDATE_ENDPOINT_DETAILS,
  payload: {
    machine_name,
    details,
  },
});

export const getEndpointStatus = (id) => ({
  type: GET_ENDPOINT_STATUS_REQUEST,
  payload: {
    id,
  },
});

export const getEndpointStatusUpdate = (id, data) => ({
  type: GET_ENDPOINT_STATUS_SUCCESS,
  payload: {
    id,
    response: data,
  },
});

import {
  all, call, fork, put, takeEvery, select,
} from 'redux-saga/effects';
import {
  transferSessionSuccess,
  cancelTransferSessionSuccess,
  getTransfersSuccess,
} from '@actions/iObserverActions/SessionActions';

import {
  getSelectedEndpointMachineNames,
  getTransferId,
  getAdminId,
  getProfile,
} from '@selectors/iObserverSelectors';

import {
  TRANSFER_SESSION_REQUEST,
  CANCEL_TRANSFER_SESSION_REQUEST,
  SEND_COMMAND_BY_USER,
  GET_TRANSFERS_REQUEST,
  START_IMPORT_SESSION,
} from '@constants/iObserverActionTypes';

import {
  startObservationSessionSuccess,
} from '@actions/iObserverActions/ObservationActions';

import {
  CONTROL_COMMANDS,
} from '@constants/Settings';

import { rest } from '@api/iObserverApi';

import { fetchError } from '@actions/Common';
import WebRTCManager from '../../../util/WebRTC/WebRTCManager';

function* transferSession() {
  try {
    const endpoint_array = yield select(getSelectedEndpointMachineNames);
    const params = {
    };
    if (endpoint_array.length) {
      params.endpoint_array = endpoint_array;
    }
    const transferSessionResponse = yield call(rest.transferSession, params);

    if (transferSessionResponse && transferSessionResponse.transfer_id) {
      yield put(transferSessionSuccess(transferSessionResponse.transfer_id));
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* cancelTransferSession() {
  try {
    const transfer_id = yield select(getTransferId);
    const endpoint_array = yield select(getSelectedEndpointMachineNames);
    const params = {
      transfer_id,
    };
    if (endpoint_array.length) {
      params.endpoint_array = endpoint_array;
    }
    const transferSessionResponse = yield call(rest.cancelTransferSession, params);

    if (transferSessionResponse && transferSessionResponse.success
      && transferSessionResponse.success === true) {
      yield put(cancelTransferSessionSuccess());
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* sendCommandByUser(data) {
  try {
    const { params } = data.payload;
    yield call(rest.sendCommandByUser, params);
  } catch (err) {
    console.warn('[Error] [send_command] sendCommandByUser', err);
    yield put(fetchError(err));
  }
}

function* getTransfers() {
  try {
    const response = yield call(rest.getTransfers);
    if (response) {
      yield put(getTransfersSuccess(response));
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

function* startImportSession(data) {
  try {
    const { transfer_id } = data.payload;
    const params = {
      transfer_id,
    };
    const takeOverSessionResponse = yield call(rest.takeOverSession, params);
    if (takeOverSessionResponse) {
      WebRTCManager.closeUserSession();
      const { r_email, new_observer_session_id } = takeOverSessionResponse;
      const admin_id = yield select(getAdminId);
      // start with transferred_session
      const startSessionResponse = yield call(rest.startObservationSession,
        admin_id, new_observer_session_id);

      if (startSessionResponse) {
        yield put(startObservationSessionSuccess(startSessionResponse));
        setTimeout(() => {
          WebRTCManager.login(`${admin_id}`);
        }, 1200);
      }
      const profile = yield select(getProfile);
      const userEmail = profile.profile.username;
      const sendCommandParams = {
        email: r_email,
        command: CONTROL_COMMANDS.REQUEST_TRANSFER_INFO,
        params_p: {
          r_email: userEmail,
        },
      };
      const sendCommandByUserResponse = yield call(rest.sendCommandByUser, sendCommandParams);
      console.log('sendCommandByUser response: ', sendCommandByUserResponse);
    }
  } catch (err) {
    yield put(fetchError(err));
  }
}

// function* loadImportedEndpoints(data) {
//
// }

export function* actionsWatcher() {
  yield takeEvery(TRANSFER_SESSION_REQUEST, transferSession);
  yield takeEvery(CANCEL_TRANSFER_SESSION_REQUEST, cancelTransferSession);
  yield takeEvery(SEND_COMMAND_BY_USER, sendCommandByUser);
  yield takeEvery(GET_TRANSFERS_REQUEST, getTransfers);
  yield takeEvery(START_IMPORT_SESSION, startImportSession);
  // yield takeEvery(LOAD_IMPORTED_ENDPOINTS, loadImportedEndpoints);
}

export default function* rootSaga() {
  yield all([
    fork(actionsWatcher),
  ]);
}

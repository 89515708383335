// eslint-disable-next-line import/prefer-default-export
export const setAudioOutput = async (element, deviceId) => {
  if (element.setSinkId) {
    try {
      await element.setSinkId(deviceId);
    } catch (e) {
      console.log(e, 'setSinkId failed with given id');
      try {
        await element.setSinkId('');
      } catch (e1) {
        console.log(e1, 'default setSinkId failed');
      }
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const layoutNames = {
  DEFAULT_LAYOUT: 'DEFAULT_LAYOUT',
  LAYOUT_3_ROOMS_LEFT: 'LAYOUT_3_ROOMS_LEFT',
  LAYOUT_3_ROOMS_RIGHT: 'LAYOUT_3_ROOMS_RIGHT',
  LAYOUT_6_ROOMS_LEFT: 'LAYOUT_6_ROOMS_LEFT',
  LAYOUT_6_ROOMS_RIGHT: 'LAYOUT_6_ROOMS_RIGHT',
  LAYOUT_6_ROOMS_SYMMETRIC: 'LAYOUT_6_ROOMS_SYMMETRIC',
  LAYOUT_6_ROOMS_EQUAL: 'LAYOUT_6_ROOMS_EQUAL',
  LAYOUT_12_ROOMS_LEFT: 'LAYOUT_12_ROOMS_LEFT',
  LAYOUT_12_ROOMS_EQUAL: 'LAYOUT_12_ROOMS_EQUAL',
};
